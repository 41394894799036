import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

import SEO from "../components/SEO"
import PageTitle from "../components/PageTitle"
import HeroContainer from "../components/heroContainer"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"
import VideoPlayer from "../components/video/VideoPlayer"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const SectionTitle = ({ children, className }) => (
  <>
    <h2
      className={`text-center md:text-left text-4xl md:text-5xl mb-5 text-quaternary ${className}`}
    >
      {children}
    </h2>
    <hr />
  </>
)

const Section = ({ children, className = "" }) => (
  <section className={`mt-20 ${className}`}>{children}</section>
)

const Gallery = ({ data }) => {
  const { sanityGalleryPage, allSanityAlbums } = data
  const { videos } = sanityGalleryPage

  const fluidBanner =
    getFluidGatsbyImage(
      sanityGalleryPage?._rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || null

  const heroProps = {
    fluid: fluidBanner,

    gradient: true,
  }

  return (
    <Layout className="mx-10 text-center">
      <SEO title={sanityGalleryPage.title} />
      <HeroContainer props={heroProps} />
      <BackgroundContainer className="p-10 relative text-primary">
        <PageTitle text={sanityGalleryPage.title} />
        <Section>
          <SectionTitle className="">Albums</SectionTitle>
          {/* List or grid of albums */}
          <div className="flex gap-x-10 gap-y-10 justify-center flex-wrap mt-10   ">
            {(allSanityAlbums.edges.length > 0 &&
              allSanityAlbums.edges.map(({ node: album }) => {
                {
                  /* Use  albumCover if provided in CMS, or use first image in array as cover */
                }
                const albumCover = album.albumCover
                  ? album.albumCover.asset.fluid
                  : album.albumImages[0].asset.fluid

                {
                  /* Add opaque box shadow https://tailwindcss.com/docs/box-shadow */
                }

                {
                  /* tailwind hover transition adapted from Tailwind docs: https://tailwindcss.com/docs/transition-property  */
                }

                return (
                  <Link
                    className="w-64 h-64  transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-105 shadow-md"
                    to={`/gallery/${album.slug.current}`}
                    title={`${album.title}`}
                  >
                    <Img
                      fluid={{
                        ...albumCover,
                        aspectRatio: 1 / 1,
                      }}
                      alt={album.title}
                      className="bg-white"
                    />
                    <div className="text-center">
                      <h3 className="text-2xl mt-3 text-center text-secondary">
                        {album.title}
                      </h3>
                      {album.date && (
                        <p className="text-quaternary italic p-0">
                          {album.date}
                        </p>
                      )}
                    </div>
                  </Link>
                )
              })) || <h2 className="mt-10">No albums yet. Check back soon!</h2>}
          </div>
        </Section>
        {/* Videos */}
        {videos && (
          <Section className="">
            <SectionTitle>Videos</SectionTitle>
            {videos.map((video, index) => {
              return (
                <div className="w-full grid justify-items-center mt-14">
                  <h3 className="text-2xl md:text-3xl text-center">
                    {video.title}
                  </h3>
                  <VideoPlayer
                    key={`${video._key}-${index}`}
                    url={video.url}
                    caption={video.caption}
                  />
                </div>
              )
            })}
          </Section>
        )}
      </BackgroundContainer>
    </Layout>
  )
}

// current queries for all of the images, in all of the albums. Not ideal!

export const query = graphql`
  query GalleryPageQuery {
    sanityGalleryPage {
      title
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      videos {
        _key
        title
        url
        caption
      }
    }
    allSanityAlbums(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date(formatString: "MMMM DD, YYYY")
          slug {
            current
          }
          albumImages {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          albumCover {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Gallery
